import React, { useContext } from 'react';
import { DocsContext } from '../contexts/DocsContext';
import { asText } from '@prismicio/client';
import { useTranslation } from 'react-i18next';

const IdToTitle = (props) => {
  const { id } = props;
  const { t } = useTranslation('maastotaulukot');
  const [docs] = useContext(DocsContext);
  const doc = docs?.find((doc) => {
    return doc.id === id;
  });

  return (
    <>{doc?.doc?.data?.title?.length > 0 ? asText(doc?.doc?.data?.title) : t('app.not_found')}</>
  );
};

export default IdToTitle;
