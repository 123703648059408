import React, { useState, useEffect, useContext } from 'react';
import { Paper } from '@mui/material';
import { ReactComponent as CloseIcon } from '../assets/icons/icon-close.svg';
import BackButton from '../components/navigation/BackButton';
import { useTranslation } from 'react-i18next';
import NoConnection from '../components/utils/NoConnection';
import { AppStateContext } from '../components/contexts/AppStateContext';
import apiEndpoint from '../utils/apiEndpoint';
import axios from 'axios';
import { ReactComponent as MailIcon } from '../assets/icons/icon-mail.svg';

const AskResetPasswordLink = (props) => {
  const { online } = props;
  const { t } = useTranslation('maastotaulukot');
  const [email, setEmail] = useState('');
  const [appState, setAppState] = useContext(AppStateContext);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    document.title = `${t('reset_password.title')} - TAPIO Maastotaulukot`;
  }, [t]);

  const sendResetLink = async () => {
    setAppState({ ...appState, isLoading: true });
    await axios
      .post(`${apiEndpoint}/api/account/password/reset/`, { email: email })
      .then((response) => {
        console.log(response.data);
        setSuccess(true);
        setAppState({ ...appState, isLoading: false });
      })
      .catch((err) => {
        console.log(err.response);
        setAppState({ ...appState, errorMessage: 'unhandled_error', isLoading: false });
      });
  };

  return (
    <div className="login">
      <div className="login-wrap">
        <Paper className="login-box">
          <BackButton noWrap parentLink="/" parentTitle={t('login.title')} />
          {online === true ? (
            <>
              {!success ? (
                <>
                  <div className="title-wrap">
                    <h1>{t('reset_password.title')}</h1>
                    <p>{t('reset_password.title_message')}</p>
                  </div>
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      sendResetLink();
                    }}
                  >
                    <div className="form-group">
                      <input
                        required
                        className="form-input"
                        type="text"
                        placeholder={t('app.placeholder_email')}
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      {email.length > 0 && (
                        <span className="clear" onClick={() => setEmail('')}>
                          <CloseIcon />
                        </span>
                      )}
                    </div>
                    <button className="button default full-width" type="submit">
                      {t('app.send')}
                    </button>
                  </form>
                </>
              ) : (
                <div className="title-wrap no-margin">
                  <MailIcon />
                  <h1>{t('reset_password.email_sent_title')}</h1>
                  <p>{`${t('reset_password.email_sent_message')} ${email}`}</p>
                </div>
              )}
            </>
          ) : (
            <NoConnection />
          )}
        </Paper>
      </div>
    </div>
  );
};

export default AskResetPasswordLink;
