import React from 'react';
import { Slider, Box } from '@mui/material';

const RestrictedCounterSlider = (props) => {
  const { data, label, value, onChange, showDecimal = false } = props;

  const marks = data.map((mark, index) => ({
    index,
    value: mark.value,
  }));

  return (
    <div className="form-group counter-slider rounded">
      <label className="form-label">{label}</label>
      <div className="slider-wrap">
        <Box minWidth={55} textAlign="left">
          <p className="slider-value">{showDecimal ? value.toFixed(1) : value}</p>
        </Box>
        <Slider
          value={marks.find((mark) => mark.value === value)?.index}
          onChange={(_, newIndex) => onChange(marks.find((mark) => mark.index === newIndex)?.value)}
          marks={marks.map((mark) => ({ value: mark.index }))}
          step={null}
          min={0}
          max={marks.length - 1}
          valueLabelDisplay={'off'}
          classes={{ mark: 'hidden-marks' }}
        />
      </div>
    </div>
  );
};

export default RestrictedCounterSlider;
