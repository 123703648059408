import React, { useState, useEffect, useCallback } from 'react';
import CounterInput from './CounterInput';
import CounterSlider from './CounterSlider';
import { Tabs, Tab } from '@mui/material';

const constantsFor = (type) =>
  ({
    // Mänty
    0: (t, g, dgM) => {
      const a = Math.exp(0.8418 + 0.06883 * dgM);
      const c = Math.exp(0.2998 - 0.00258 * t - 0.01224 * g - 0.03332 * a + 0.06511 * dgM);
      const b = (dgM - a) / Math.pow(-Math.log(0.5), 1 / c);
      return { a, b, c };
    },
    // Kuusi
    1: (t, g, dgM) => {
      const a = 0.001389 + 0.517444 * dgM;
      const b = 0.629537 + 1.050618 * dgM - 1.020776 * a + 0.014405 * g - 0.001986 * t;
      const c = Math.log(-Math.log(0.5)) / Math.log((dgM - a) / b);
      return { a, b, c };
    },
    // Koivu (=Mänty)
    2: (t, g, dgM) => {
      const a = Math.exp(0.8418 + 0.06883 * dgM);
      const c = Math.exp(0.2998 - 0.00258 * t - 0.01224 * g - 0.03332 * a + 0.06511 * dgM);
      const b = (dgM - a) / Math.pow(-Math.log(0.5), 1 / c);
      return { a, b, c };
    },
  }[type]);

const computeWeibull = (type, a, b, c, t, g, dgM) => {
  const compute = (n) => {
    const F_d =
      type === 1
        ? 1 - Math.exp(-1 * Math.pow((n + 0.5 - a) / b, c))
        : 1 - Math.exp(-1 * Math.pow((n + 0.5 - (a - 0.5)) / b, c));
    const G = Math.PI * Math.pow(n / (2 * 100), 2);

    if (n === 1) {
      return {
        F_d,
        f_d: 0,
        f_d_G: 0,
        G,
        N: 0,
        sum_N: 0,
      };
    }

    const previous = compute(n - 1);
    const f_d = F_d - (isNaN(previous.F_d) ? 0 : previous.F_d);
    const f_d_G = f_d * g;
    const N = f_d_G / G;

    return {
      F_d,
      f_d,
      f_d_G,
      G,
      N,
      sum_N:
        (!isNaN(previous.F_d) &&
        !isNaN(previous.f_d) &&
        !isNaN(previous.f_d_G) &&
        !isNaN(previous.G) &&
        !isNaN(N)
          ? N
          : 0) + previous.sum_N,
    };
  };

  return compute;
};

const FromBaseAreaToTrunkCount = (props) => {
  const { cm, m2Ha, species } = props;
  const [tab, setTab] = useState(0);

  const [g, setG] = useState(null);
  const [dgM, setDgM] = useState(null);
  const [t, setT] = useState(null);

  const [computedValue, setComputedValue] = useState(0);

  const computeValue = useCallback(() => {
    const { a, b, c } = constantsFor(tab)(t, g, dgM);
    const weibull = computeWeibull(tab, a, b, c, t, g, dgM)(58).sum_N;

    if (weibull) {
      setComputedValue(weibull);
    }
  }, [dgM, g, t, tab]);

  useEffect(() => {
    if (g && dgM && t) {
      computeValue();
    }
  }, [g, dgM, t, computeValue]);

  useEffect(() => {
    if (cm && m2Ha) {
      setG(m2Ha);
      setDgM(cm);
      if (species === 2) {
        setT(50);
      } else {
        setT(60);
      }
      setTab(species);
    } else {
      // Mänty
      if (tab === 0) {
        setG(20);
        setDgM(20);
        setT(60);
      }
      // Kuusi
      else if (tab === 1) {
        setG(25);
        setDgM(20);
        setT(60);
      }
      // Koivu
      else if (tab === 2) {
        setG(20);
        setDgM(20);
        setT(50);
      }
    }
  }, [tab, cm, m2Ha, species]);

  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  if (cm && m2Ha) {
    return (
      <CounterInput
        id=""
        type="number"
        toFixed={0}
        label="Runkoluku (kpl/ha)"
        value={computedValue}
        placeholder="0"
        result
      />
    );
  } else {
    return (
      <div className="slice counter">
        <Tabs value={tab} onChange={handleChange}>
          <Tab label="Mänty" />
          <Tab label="Kuusi" />
          <Tab label="Koivu" />
        </Tabs>

        <CounterSlider
          defaultValue={0}
          label="Pohjapinta-ala (m²/ha)"
          min={5}
          max={40}
          value={g}
          onChange={(e, newValue) => setG(newValue)}
        />

        <CounterSlider
          defaultValue={0}
          label="Pohjapinta-alalla painotettu läpimitta (cm)"
          min={6}
          max={30}
          value={dgM}
          onChange={(e, newValue) => setDgM(newValue)}
        />

        <CounterSlider
          defaultValue={0}
          label="Ikä"
          min={6}
          max={100}
          value={t}
          onChange={(e, newValue) => setT(newValue)}
        />

        <div className="form-row result">
          <CounterInput
            id=""
            type="number"
            toFixed={0}
            label="Runkoluku (kpl/ha)"
            value={computedValue}
            placeholder="0"
            result
          />
        </div>
      </div>
    );
  }
};

export default FromBaseAreaToTrunkCount;
