import React, { useState, useEffect, useContext } from 'react';
import { Paper } from '@mui/material';
import { ReactComponent as CloseIcon } from '../assets/icons/icon-close.svg';
import { ReactComponent as EyeIcon } from '../assets/icons/icon-eye.svg';
import { ReactComponent as EyeOffIcon } from '../assets/icons/icon-eye-off.svg';
import { Link } from 'react-router-dom';
import BackButton from '../components/navigation/BackButton';
import { useTranslation } from 'react-i18next';
import apiEndpoint from '../utils/apiEndpoint';
import verifyPassword from '../utils/verifyPassword';
import axios from 'axios';
import { ReactComponent as MailIcon } from '../assets/icons/icon-mail.svg';
import NoConnection from '../components/utils/NoConnection';
import { AppStateContext } from '../components/contexts/AppStateContext';

const Register = (props) => {
  const { online } = props;
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [registerEmail, setRegisterEmail] = useState('');
  const [registerPw, setRegisterPw] = useState('');
  const [registerPwRepeat, setRegisterPwRepeat] = useState('');
  const [pwVisible, setPwVisible] = useState(false);
  const { t } = useTranslation('maastotaulukot');
  const [success, setSuccess] = useState(false);
  const [appState, setAppState] = useContext(AppStateContext);

  useEffect(() => {
    document.title = `${t('register.title')} - TAPIO Maastotaulukot`;
  }, [t]);

  // Try to register user last
  const registerUser = async () => {
    setAppState({ ...appState, isLoading: true });

    await axios
      .post(`${apiEndpoint}/api/account/register/`, {
        first_name: firstName,
        last_name: lastName,
        email: registerEmail,
        password1: registerPw,
        password2: registerPw,
      })
      .then((response) => {
        console.log(response.data);
        setSuccess(true);
        setAppState({ ...appState, isLoading: false });
      })
      .catch((err) => {
        console.log(err.response);
        if (err.response?.status === 400) {
          setAppState({ ...appState, errorMessage: 'email_already_registered', isLoading: false });
        } else {
          setAppState({ ...appState, errorMessage: 'unhandled_error', isLoading: false });
        }
      });
  };

  // Verify user input first
  const verifyUser = async () => {
    setAppState({ ...appState, isLoading: true });

    if (registerPw === registerPwRepeat) {
      await verifyPassword(registerPw)
        .then((response) => {
          console.log(response.data);
          if (response.data?.accepted === true) {
            registerUser();
          } else {
            setAppState({
              ...appState,
              errorMessage: response.data?.warning[0]?.code,
              isLoading: false,
            });
          }
        })
        .catch((err) => {
          console.log(err.response);
          setAppState({ ...appState, errorMessage: 'unhandled_error', isLoading: false });
        });
    } else {
      setAppState({ ...appState, errorMessage: 'passwords_not_equal', isLoading: false });
    }
  };

  return (
    <div className="login">
      <div className="login-wrap">
        <Paper className="login-box">
          {online === true ? (
            <>
              <BackButton noWrap parentLink="/" parentTitle={t('login.title')} />
              {!success ? (
                <>
                  <div className="title-wrap">
                    <h1>{t('register.title')}</h1>
                    <p>
                      {t('register.title_message')}{' '}
                      <a
                        href="https://www.metsakauppa.fi/tuote/maastotaulukot-mobiili/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        {t('register.title_message_webstore')}
                      </a>
                    </p>
                  </div>

                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      verifyUser();
                    }}
                  >
                    <div className="form-group">
                      <input
                        required
                        className="form-input"
                        type="text"
                        placeholder={t('app.placeholder_first_name')}
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                      />
                      {firstName.length > 0 && (
                        <span className="clear" onClick={() => setFirstName('')}>
                          <CloseIcon />
                        </span>
                      )}
                    </div>

                    <div className="form-group">
                      <input
                        required
                        className="form-input"
                        type="text"
                        placeholder={t('app.placeholder_last_name')}
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                      />
                      {lastName.length > 0 && (
                        <span className="clear" onClick={() => setLastName('')}>
                          <CloseIcon />
                        </span>
                      )}
                    </div>

                    <div className="form-group">
                      <input
                        required
                        className="form-input"
                        type="email"
                        placeholder={t('app.placeholder_email')}
                        value={registerEmail}
                        onChange={(e) => setRegisterEmail(e.target.value)}
                      />
                      {registerEmail.length > 0 && (
                        <span className="clear" onClick={() => setRegisterEmail('')}>
                          <CloseIcon />
                        </span>
                      )}
                    </div>

                    <div className="form-group">
                      <input
                        required
                        className="form-input"
                        type={pwVisible ? 'text' : 'password'}
                        placeholder={t('app.placeholder_password')}
                        value={registerPw}
                        onChange={(e) => setRegisterPw(e.target.value)}
                      />
                      {registerPw.length > 0 && (
                        <>
                          <span className="eye" onClick={() => setPwVisible(!pwVisible)}>
                            {pwVisible ? <EyeOffIcon /> : <EyeIcon />}
                          </span>
                          <span className="clear" onClick={() => setRegisterPw('')}>
                            <CloseIcon />
                          </span>
                        </>
                      )}
                    </div>
                    <div className="form-group">
                      <input
                        required
                        className="form-input"
                        type={pwVisible ? 'text' : 'password'}
                        placeholder={t('app.placeholder_repeat_password')}
                        value={registerPwRepeat}
                        onChange={(e) => setRegisterPwRepeat(e.target.value)}
                      />
                      {registerPwRepeat.length > 0 && (
                        <>
                          <span className="eye" onClick={() => setPwVisible(!pwVisible)}>
                            {pwVisible ? <EyeOffIcon /> : <EyeIcon />}
                          </span>
                          <span className="clear" onClick={() => setRegisterPwRepeat('')}>
                            <CloseIcon />
                          </span>
                        </>
                      )}
                    </div>

                    <button className="button default full-width" type="submit">
                      {t('register.title')}
                    </button>
                    <div className="register-text">
                      <Link className="button link" to="/privacy-policy">
                        {t('privacy.title')}
                      </Link>
                    </div>
                  </form>
                </>
              ) : (
                <div className="title-wrap no-margin">
                  <MailIcon />
                  <h1>{t('register.success_title')}</h1>
                  <p>{`${t('register.success_message')} ${registerEmail}`}</p>
                </div>
              )}
            </>
          ) : (
            <NoConnection />
          )}
        </Paper>
      </div>
    </div>
  );
};

export default Register;
