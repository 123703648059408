import React, { useContext, useEffect, useState } from 'react';
import { DocsContext } from '../components/contexts/DocsContext';
import BackButton from '../components/navigation/BackButton';
import { ReactComponent as ChevronRight } from '../assets/icons/icon-chevron-right.svg';
import { List, ListItem, ListItemText, ListItemSecondaryAction, Divider } from '@mui/material';
import IdToTitle from '../components/utils/IdToTitle';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CloseIcon } from '../assets/icons/icon-close.svg';
import { Link, useLocation } from 'react-router-dom';
import { Navigate } from 'react-router-dom';
import { UserContext } from '../components/contexts/UserContext';

const Search = (props) => {
  const { pathname } = useLocation();
  const [docs] = useContext(DocsContext);
  const [search, setSearch] = useState('');
  const { t } = useTranslation('maastotaulukot');
  const [user] = useContext(UserContext);
  const [isValidLicense, setIsValidLicense] = useState(true);

  let filteredDocs = [];
  if (docs.length > 0) {
    filteredDocs = docs
      .filter((doc) => {
        return doc.doc.type === 'page';
      })
      .map((doc) => {
        return { id: doc.id, content: doc.doc.data };
      });
  }

  let results = [];
  if (filteredDocs.length > 0 && search.length >= 3) {
    results = filteredDocs.filter((doc) => {
      return JSON.stringify(doc).toLowerCase().includes(search.toLowerCase());
    });
  }

  useEffect(() => {
    document.title = `${t('search.title')} - TAPIO Maastotaulukot`;
  }, [t]);

  // Check license from user data
  useEffect(() => {
    if (user?.expires && new Date(user?.expires) < new Date()) {
      setIsValidLicense(false);
    }
  }, [user, pathname]);

  return isValidLicense ? (
    <div className="page search">
      <BackButton />
      <div className="input-wrap">
        <div className="form-group rounded">
          <input
            className="form-input"
            type="text"
            placeholder={t('search.placeholder')}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          {search.length > 0 && (
            <span
              className="clear"
              onClick={() => {
                setSearch('');
              }}
            >
              <CloseIcon />
            </span>
          )}
        </div>
      </div>
      <h2 className="results-count">{`${results.length} ${t(
        'search.results_message'
      )}: ${search}`}</h2>
      <div className="slices-container">
        <List className="nav-slice" style={{ display: results.length > 0 ? 'block' : 'none' }}>
          {results.map((item, i) => {
            return (
              <Link key={i} to={`/pages/${item.id}`} onClick={() => setSearch('')}>
                <ListItem>
                  <ListItemText
                    primary={
                      <h4>
                        <IdToTitle id={item.id} />
                      </h4>
                    }
                  />
                  <ListItemSecondaryAction>
                    <ChevronRight />
                  </ListItemSecondaryAction>
                </ListItem>
                <Divider />
              </Link>
            );
          })}
        </List>
      </div>
    </div>
  ) : (
    <Navigate replace to="activate" />
  );
};

export default Search;
