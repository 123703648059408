import React from 'react';
import { Slider } from '@mui/material';

const CounterSlider = (props) => {
  const { defaultValue, label, min, max, value, onChange } = props;

  return (
    <div className="form-group counter-slider rounded">
      <label className="form-label">{label}</label>
      <div className="slider-wrap">
        <p className="slider-value">{value}</p>
        <Slider
          defaultValue={defaultValue}
          min={min}
          max={max}
          value={typeof value === 'number' ? value : 0}
          onChange={onChange}
        />
      </div>
    </div>
  );
};

export default CounterSlider;
