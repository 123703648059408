import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Divider,
  ListItemIcon,
} from '@mui/material';
import { ReactComponent as ChevronRight } from '../../assets/icons/icon-chevron-right.svg';
import { ReactComponent as ChevronDown } from '../../assets/icons/icon-chevron-down.svg';
import customLink from '../../utils/hyperLinkSerializer';
import { asText } from '@prismicio/client';
import { DocsContext } from '../contexts/DocsContext';
import CrushedAshCounter from '../counters/CrushedAshCounter';
import FromBaseAreaToTrunkCount from '../counters/FromBaseAreaToTrunkCount';
import HeatAmountsByMunicipality from '../counters/HeatAmountsByMunicipality';
import VolumeOfSingleTree from '../counters/VolumeOfSingleTree';
import RelascopeTables from '../counters/RelascopeTables';
import ThinningPatterns from '../counters/ThinningPatterns';
import Image from './Image';
import { AppStateContext } from '../contexts/AppStateContext';
import CalcIcon from '../../assets/icons/calculator.png';
import { PrismicRichText } from '@prismicio/react';

const Slices = (props) => {
  const { slices, doc } = props;
  const [docs] = useContext(DocsContext);
  const [appState] = useContext(AppStateContext);

  const isMaastotaulukotEnabled = doc?.type === 'home' && appState.unlockedContent.maastotaulukot;
  const isSummaArvotEnabled = doc?.type === 'home' && appState.unlockedContent.summa_arvotaulukot;

  const docSlices = slices?.map((slice, i) => {
    const children = docs?.filter((item) => item.doc?.data?.parent?.id === doc?.id);

    if (slice.slice_type === 'valikko') {
      return (
        <List key={i} className="nav-slice slice">
          <>
            {doc?.type === 'home' &&
              isMaastotaulukotEnabled &&
              slice.primary?.show_children === true &&
              children
                ?.sort((a, b) => {
                  const x = a.doc?.data?.order ? a.doc.data.order : 999;
                  const y = b.doc?.data?.order ? b.doc.data.order : 999;
                  return x - y;
                })
                .map((item, i) => {
                  let disabled = false;
                  const url = item.doc?.data?.icon?.url;
                  let path;
                  if (item.doc?.type === 'page') {
                    path = `/pages/${item.id}`;
                  } else {
                    path = `/${item.doc?.type.replaceAll('_', '-')}`;
                  }

                  return (
                    <Link
                      className={disabled === true ? 'disabled' : 'not-disabled'}
                      key={i}
                      to={path}
                    >
                      <ListItem>
                        {url && (
                          <ListItemIcon>
                            <Image src={url} />
                          </ListItemIcon>
                        )}
                        <ListItemText primary={<h4>{item.doc?.data?.title[0]?.text}</h4>} />
                        <ListItemSecondaryAction>
                          <ChevronRight />
                        </ListItemSecondaryAction>
                      </ListItem>
                      <Divider />
                    </Link>
                  );
                })}

            {doc?.type !== 'home' &&
              slice.primary?.show_children === true &&
              children
                ?.sort((a, b) => {
                  const x = a.doc?.data?.order ? a.doc.data.order : 999;
                  const y = b.doc?.data?.order ? b.doc.data.order : 999;
                  return x - y;
                })
                .map((item, i) => {
                  let disabled = false;
                  const url = item.doc?.data?.icon?.url;
                  let path;
                  if (item.doc?.type === 'page') {
                    path = `/pages/${item.id}`;
                  } else {
                    path = `/${item.doc?.type.replaceAll('_', '-')}`;
                  }

                  return (
                    <Link
                      className={disabled === true ? 'disabled' : 'not-disabled'}
                      key={i}
                      to={path}
                    >
                      <ListItem>
                        {url && (
                          <ListItemIcon>
                            <Image src={url} />
                          </ListItemIcon>
                        )}
                        <ListItemText primary={<h4>{item.doc?.data?.title[0]?.text}</h4>} />
                        <ListItemSecondaryAction>
                          <ChevronRight />
                        </ListItemSecondaryAction>
                      </ListItem>
                      <Divider />
                    </Link>
                  );
                })}

            {slice.items
              ?.filter((item) => {
                return item.link.type !== 'broken_type';
              })
              .map((item, i) => {
                const doc = docs.find((x) => x.id === item?.link?.id);
                let disabled = false;
                const url = item.doc?.data?.icon?.url;
                let path;
                if (doc?.doc?.type === 'page') {
                  path = `/pages/${item.id}`;
                } else {
                  path = `/${doc?.doc?.type.replaceAll('_', '-')}`;
                }

                if (item.link?.link_type === 'Web' || item.link?.link_type === 'Media') {
                  return (
                    <a
                      className={disabled === true ? 'disabled' : 'not-disabled'}
                      key={i}
                      href={item.link.url}
                      target={item.link?.target === '_blank' ? '_blank' : ''}
                      rel="noreferrer"
                    >
                      <ListItem>
                        {url && (
                          <ListItemIcon>
                            <Image src={url} />
                          </ListItemIcon>
                        )}
                        <ListItemText
                          primary={<h4>{item.link?.name ? item.link?.name : item.link?.url}</h4>}
                        />
                        <ListItemSecondaryAction>
                          <ChevronRight />
                        </ListItemSecondaryAction>
                      </ListItem>
                      <Divider />
                    </a>
                  );
                } else if (doc) {
                  return (
                    <Link
                      className={disabled === true ? 'disabled' : 'not-disabled'}
                      key={i}
                      to={path}
                    >
                      <ListItem>
                        <ListItemText primary={<h4>{asText(doc.doc.data.title)}</h4>} />
                        <ListItemSecondaryAction>
                          <ChevronRight />
                        </ListItemSecondaryAction>
                      </ListItem>
                      <Divider />
                    </Link>
                  );
                } else return null;
              })}

            {isSummaArvotEnabled && (
              <Link className={'not-disabled'} key={i} to={'/summa-arvot'}>
                <ListItem>
                  <ListItemIcon>
                    <img className="serialized-image" src={CalcIcon} alt={'Summa-arvot'} />
                  </ListItemIcon>
                  <ListItemText primary={<h4>{'Summa-arvot'}</h4>} />
                  <ListItemSecondaryAction>
                    <ChevronRight />
                  </ListItemSecondaryAction>
                </ListItem>
                <Divider />
              </Link>
            )}
          </>
        </List>
      );
    } else if (slice.slice_type === 'haitari') {
      return (
        <Accordion key={i} className="accordion-slice slice">
          <AccordionSummary expandIcon={<ChevronDown />}>
            <div>
              <PrismicRichText field={slice.primary?.title} internalLinkComponent={customLink} />
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div>
              <PrismicRichText field={slice.primary?.content} internalLinkComponent={customLink} />
            </div>
          </AccordionDetails>
        </Accordion>
      );
    } else if (slice.slice_type === 'otsikko' || slice.slice_type === 'tekstikappale') {
      return (
        <div key={i} className="paragraph-slice slice">
          <PrismicRichText field={slice.primary?.content} internalLinkComponent={customLink} />
        </div>
      );
    } else if (slice.slice_type === 'kuva') {
      const url = slice.primary?.image?.url;
      if (url) {
        return (
          <div key={i} className="image-slice slice">
            <Image src={url} />
          </div>
        );
      } else return null;
    } else if (slice.slice_type === 'tuhkamurskelaskuri') {
      return <CrushedAshCounter key={i} />;
    } else if (slice.slice_type === 'pohjapinta-alasta_runkoluvuksi') {
      return <FromBaseAreaToTrunkCount key={i} />;
    } else if (slice.slice_type === 'lamposummat_kunnittain') {
      return <HeatAmountsByMunicipality key={i} />;
    } else if (slice.slice_type === 'yksittaisen_puun_tilavuus') {
      return <VolumeOfSingleTree key={i} slice={slice} />;
    } else if (slice.slice_type === 'relaskooppitaulukot') {
      return <RelascopeTables key={i} />;
    } else if (slice.slice_type === 'html-koodi') {
      return (
        <div
          key={i}
          className="html-slice slice"
          dangerouslySetInnerHTML={{ __html: slice.primary?.content[0]?.text }}
        />
      );
    } else if (slice.slice_type === 'harvennusmallit_2023') {
      return <ThinningPatterns key={i} slice={slice} />;
    } else return null;
  });

  return <>{docSlices}</>;
};

export default Slices;
