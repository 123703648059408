import { useEffect } from 'react';
import BackButton from '../components/navigation/BackButton';
import { Link } from 'react-router-dom';
import { List, ListItem, ListItemText, ListItemSecondaryAction, Divider } from '@mui/material';
import { ReactComponent as ChevronRight } from '../assets/icons/icon-chevron-right.svg';

const SummaArvotHome = () => {
  useEffect(() => {
    document.title = 'Summa-arvot - TAPIO Maastotaulukot';
  }, []);

  return (
    <div className="page">
      <BackButton parentTitle={'Aloitusnäkymä'} />

      <div className="page-title">
        <h1>{'Summa-arvot'}</h1>
      </div>

      <div className={'slices-container'}>
        <List className="nav-slice slice">
          <Link className={'not-disabled'} to={'/summa-arvot/areas'}>
            <ListItem>
              <ListItemText primary={<h4>{'Summa-arvot laskenta-alueittain'}</h4>} />
              <ListItemSecondaryAction>
                <ChevronRight />
              </ListItemSecondaryAction>
            </ListItem>
            <Divider />
          </Link>

          <Link className={'not-disabled'} to={'/summa-arvot/instructions'}>
            <ListItem>
              <ListItemText primary={<h4>{'Ohjeet'}</h4>} />
              <ListItemSecondaryAction>
                <ChevronRight />
              </ListItemSecondaryAction>
            </ListItem>
            <Divider />
          </Link>
        </List>
      </div>
    </div>
  );
};

export default SummaArvotHome;
