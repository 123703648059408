import React, { useContext, useEffect, useState } from 'react';
import { DocsContext } from '../components/contexts/DocsContext';
import Slices from '../components/utils/Slices';
import BackButton from '../components/navigation/BackButton';
import packageJson from '../../package.json';
import { buildInfo } from '../buildInfo';
import { useTranslation } from 'react-i18next';
import { AppStateContext } from '../components/contexts/AppStateContext';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { PrismicRichText } from '@prismicio/react';

const Page = () => {
  const { pathname } = useLocation();
  const { id } = useParams();
  const navigate = useNavigate();
  const [docs] = useContext(DocsContext);
  const [doc, setDoc] = useState({});
  const [notFound, setNotFound] = useState(false);
  const { t } = useTranslation('maastotaulukot');
  const [appState, setAppState] = useContext(AppStateContext);
  const { isLoading } = appState;

  useEffect(() => {
    if (!notFound) {
      document.title = `${
        doc?.data?.title?.length > 0 ? `${doc?.data?.title[0]?.text} - ` : ''
      }TAPIO Maastotaulukot`;
    } else {
      document.title = `${t('app.not_found')} - TAPIO Maastotaulukot`;
    }
  }, [doc, notFound, t]);

  // Select correct doc form list of docs
  useEffect(() => {
    const docCopy = docs.find((item) => {
      if (pathname === '/') {
        return item.doc.type === 'home';
      } else if (pathname === '/summa-arvot/instructions') {
        return item.doc?.tags.includes('summa-arvo-ohje');
      } else if (!pathname.includes('/pages/')) {
        return (
          item.doc?.type === pathname.replace('/', '').replace('-', '_') &&
          !item.doc?.tags.includes('summa-arvo-ohje')
        );
      } else {
        return item.id === id && !item.doc?.tags.includes('summa-arvo-ohje');
      }
    });

    if (docs.length > 0) {
      if (isLoading) {
        setAppState((state) => {
          return { ...state, isLoading: false };
        });
      }
      if (docCopy) {
        setNotFound(false);
        setDoc(docCopy.doc);
      } else {
        setNotFound(true);
      }
    } else if (docs.length === 0 && !notFound) {
      if (!isLoading) {
        setAppState((state) => {
          return { ...state, isLoading: true };
        });
      }
    } else {
      if (!isLoading) {
        setAppState((state) => {
          return { ...state, isLoading: true };
        });
      }
    }
  }, [docs, pathname, id, notFound, isLoading, setAppState]);

  return (
    <div className="page">
      {notFound ? (
        <>
          {pathname !== '/' && <BackButton />}
          <div className="page-title">
            <h1>{t('app.not_found')}</h1>
          </div>
          <div className="slices-container">
            <p>{t('app.not_found_message')}</p>
            {pathname !== '/' && (
              <button onClick={() => navigate('/')} className="button default small">
                {t('app.to_homepage')}
              </button>
            )}
          </div>
        </>
      ) : (
        <>
          {pathname !== '/' && <BackButton doc={doc} />}
          {doc?.data?.title?.length > 0 && doc?.type !== 'home' && (
            <div className="page-title">
              <PrismicRichText field={doc?.data?.title} />
            </div>
          )}
          <div className={pathname === '/' ? 'slices-container home' : 'slices-container'}>
            <Slices doc={doc} slices={doc?.data?.body} type={doc?.data?.type} />
            {pathname === '/about' && (
              <div className="build-info">
                <h2>Sovelluksen versio</h2>
                <p>versionumero: {packageJson.version}</p>
                {buildInfo?.buildDate && <p>versiopvm: {buildInfo.buildDate}</p>}
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default Page;
