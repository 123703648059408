import RestrictedCounterSlider from './RestrictedCounterSlider';
import CounterInput from './CounterInput';
import { Tabs, Tab, Divider } from '@mui/material';
import { useState } from 'react';

const SummaArvotCounter = ({ data, specialConditions }) => {
  const mData = data
    .filter((mark) => mark['Puuston keskipituus [m]'].value !== null)
    .map((mark) => ({
      value: mark['Puuston keskipituus [m]'].value,
      column: mark.column,
    }));

  const vData = data
    .filter((mark) => mark['Puuston ikä [v]'].value !== null)
    .map((mark) => ({
      value: mark['Puuston ikä [v]'].value,
      column: mark.column,
    }));

  const [currentTab, setCurrentTab] = useState('Puuston keskipituus');
  const [m, setM] = useState(mData[0].value); // Puuston keskipituus
  const [v, setV] = useState(vData[0].value); // Puuston ikä

  const currentColumnData = data.find((mark) => mark['Puuston keskipituus [m]'].value === m);
  const columnSpecialConditions = currentColumnData?.columnSpecialConditions
    ? currentColumnData.columnSpecialConditions.map((spCond) => specialConditions[spCond])
    : undefined;

  return (
    <>
      <Tabs value={currentTab} onChange={(_, value) => setCurrentTab(value)}>
        <Tab value={'Puuston keskipituus'} label={'Puuston keskipituus'} />
        <Tab value={'Puuston ikä'} label={'Puuston ikä'} />
      </Tabs>

      {currentTab === 'Puuston keskipituus' && (
        <RestrictedCounterSlider
          label="Puuston keskipituus [m]"
          value={m}
          data={mData}
          showDecimal
          onChange={(newValue) => {
            setM(newValue);

            const column = mData.find((mark) => mark.value === newValue)?.column;
            const matchingV = vData.find((mark) => mark.column === column)?.value;

            setV(matchingV);
          }}
        />
      )}

      {currentTab === 'Puuston ikä' && (
        <RestrictedCounterSlider
          label="Puuston ikä [v]"
          value={v}
          data={vData}
          onChange={(newValue) => {
            setV(newValue);

            const column = vData.find((mark) => mark.value === newValue)?.column;
            const matchingM = mData.find((mark) => mark.column === column)?.value;

            setM(matchingM);
          }}
        />
      )}

      <div className="form-row result">
        <CounterInput
          type="number"
          label="Tilavuus [m³/ha]"
          value={currentColumnData['Tilavuus [m3/ha]'].value ?? '-'}
          placeholder="-"
          result
        />
        <CounterInput
          type="number"
          label="Tukkiprosentti [%]"
          value={
            currentColumnData['Tukkiprosentti [%]'].value
              ? currentColumnData['Tukkiprosentti [%]'].value * 100
              : '-'
          }
          placeholder="-"
          result
        />
      </div>
      <div className="form-row result">
        <CounterInput
          type="number"
          label="Hakkuuarvo [€/ha]"
          value={currentColumnData['Hakkuuarvo [€/ha]'].value ?? '-'}
          placeholder="-"
          result
        />
        <CounterInput
          type="number"
          label="Odotusarvo [€/ha]"
          value={currentColumnData['Odotusarvo [€/ha]'].value ?? '-'}
          placeholder="-"
          result
        />
      </div>
      <div className="form-row result padded">
        <CounterInput
          type="number"
          label="Odotusarvokerroin"
          value={
            currentColumnData['Odotusarvokerroin'].value
              ? currentColumnData['Odotusarvokerroin'].value.toFixed(2)
              : '-'
          }
          placeholder="-"
          result
          highlight={currentColumnData['Odotusarvokerroin'].preferred}
          highlightColor={'#DFF0D8'}
        />
        <CounterInput
          type="number"
          label="Puuston kokonaisarvo [€/ha]"
          value={currentColumnData['Puuston kokonaisarvo [€/ha]'].value ?? '-'}
          placeholder="-"
          result
          highlight={currentColumnData['Puuston kokonaisarvo [€/ha]'].preferred}
          highlightColor={'#DFF0D8'}
        />
      </div>

      <p className="small">
        <strong>{'Ensisijaisesti suositeltu arvonmääritystapa: '}</strong>
        {currentColumnData['Puuston kokonaisarvo [€/ha]'].preferred
          ? 'Puuston kokonaisarvo [€/ha]'
          : 'Odotusarvokerroin'}
      </p>
      {columnSpecialConditions && (
        <>
          <Divider />
          <div className="special-condition-container">
            <p style={{ color: '#09984a' }}>
              <strong>{'Seuraavat toimenpiteet oletettu tehdyksi:'}</strong>
            </p>
            <ol>
              {columnSpecialConditions.map((spCond) => (
                <li key={spCond}>{spCond}</li>
              ))}
            </ol>
          </div>
        </>
      )}
    </>
  );
};

export default SummaArvotCounter;
