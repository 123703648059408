import { useEffect } from 'react';
import { useLocation } from 'react-router';

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    const unlisten = () => {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'instant',
      });
    };

    return () => {
      unlisten();
    };
  }, [pathname]);

  return null;
};

export default ScrollToTop;
