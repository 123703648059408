import React, { useContext, useEffect, useState } from 'react';
import IdToTitle from '../utils/IdToTitle';
import { DocsContext } from '../contexts/DocsContext';
import _ from 'lodash';
import { useLocation } from 'react-router';

const Breadcrumbs = (props) => {
  const [docs] = useContext(DocsContext);
  const { id } = props;
  const [currentDoc, setCurrentDoc] = useState({});
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname && docs.length > 0) {
      const doc = docs.find((x) => x.id === id);

      if (doc?.doc) {
        setCurrentDoc(doc.doc);
      } else {
        setCurrentDoc(null);
      }
    }
  }, [pathname, docs, id]);

  useEffect(() => {
    if (!_.isEmpty(currentDoc)) {
      let parents = [currentDoc];
      while (true) {
        const parent = docs.find((x) => x.id === parents[parents.length - 1]?.data?.parent?.id);
        if (parent?.doc) {
          parents.push(parent?.doc);
        } else {
          break;
        }
      }
      setBreadcrumbs(parents);
    }
  }, [currentDoc, docs]);

  return (
    currentDoc && (
      <>
        {[...breadcrumbs].reverse().map((breadcrumb, i) => {
          if (i > 0 && i < breadcrumbs.length - 1) {
            return (
              <React.Fragment key={i}>
                <IdToTitle id={breadcrumb.id} /> {i < breadcrumbs.length - 2 && '/ '}
              </React.Fragment>
            );
          } else return null;
        })}
      </>
    )
  );
};

export default Breadcrumbs;
