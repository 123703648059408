import React, { useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ReactComponent as ChevronLeft } from '../../assets/icons/icon-chevron-left-green.svg';
import { DocsContext } from '../contexts/DocsContext';
import { asText } from '@prismicio/client';
import { useTranslation } from 'react-i18next';

const BackButton = (props) => {
  const { doc, parentLink, parentTitle, onClick, noWrap } = props;
  const [docs] = useContext(DocsContext);
  const parentIndex = docs?.findIndex((x) => x.id === doc?.data?.parent?.id);
  const parent = docs[parentIndex];
  const { t } = useTranslation('maastotaulukot');
  const navigate = useNavigate();

  if (parent) {
    return (
      <div className={noWrap ? 'backbutton no-wrap' : 'backbutton'}>
        <div className="wrap">
          <Link to={`${parent.doc.type !== 'home' ? `/pages/${parent.id}` : '/'}`}>
            <div>
              <ChevronLeft />
              <span>
                {parent?.doc?.data?.title?.length > 0 ? asText(parent?.doc?.data?.title) : ''}
              </span>
            </div>
          </Link>
        </div>
      </div>
    );
  } else if (parentLink && parentTitle) {
    return (
      <div className={noWrap ? 'backbutton no-wrap' : 'backbutton'}>
        <div className="wrap">
          <Link to={parentLink}>
            <div>
              <ChevronLeft />
              <span>{parentTitle}</span>
            </div>
          </Link>
        </div>
      </div>
    );
  } else if (parentTitle) {
    return (
      <div className={noWrap ? 'backbutton no-wrap' : 'backbutton'}>
        <div className="wrap">
          <button onClick={() => navigate(-1)}>
            <div>
              <ChevronLeft />
              <span>{parentTitle}</span>
            </div>
          </button>
        </div>
      </div>
    );
  } else if (onClick) {
    return (
      <div className={noWrap ? 'backbutton no-wrap' : 'backbutton'}>
        <div className="wrap">
          <button onClick={onClick}>
            <div>
              <ChevronLeft />
              <span>{t('backbutton.title')}</span>
            </div>
          </button>
        </div>
      </div>
    );
  } else {
    return (
      <div className={noWrap ? 'backbutton no-wrap' : 'backbutton'}>
        <div className="wrap">
          <button onClick={() => navigate(-1)}>
            <div>
              <ChevronLeft />
              <span>{t('backbutton.title')}</span>
            </div>
          </button>
        </div>
      </div>
    );
  }
};

export default BackButton;
