import { useCallback, useContext, useEffect, useRef } from 'react';
import apiEndpoint from '../../utils/apiEndpoint';
import axios from 'axios';
import { AppStateContext } from '../contexts/AppStateContext';

// Tokens expire in 5 minutes, make check every 4 minutes
const refreshInterval = 4 * 60 * 1000;

const TokenRefresher = (props) => {
  const { online } = props;
  const [appState, setAppState] = useContext(AppStateContext);

  const refreshIntervalRef = useRef(null);

  const refreshTokens = useCallback(
    async (token) => {
      try {
        const response = await axios.post(`${apiEndpoint}/api/auth/refresh/`, { refresh: token });

        const newToken = response.data?.refresh;
        const prismicToken = response.data?.prismic;

        if (newToken && prismicToken) {
          window.localStorage.setItem('refresh', newToken);
          window.localStorage.setItem('token', response.data?.access);
          window.localStorage.removeItem('license'); // Force refresh of licence information on token refresh
          window.localStorage.removeItem('unlockedContent');

          setAppState((appState) => ({
            ...appState,
            isLoading: false,
            isLoggedIn: true,
            prismicToken: prismicToken,
          }));
        }
      } catch (err) {
        console.log('token refresh error: ', err.response);
      }
    },
    [setAppState]
  );

  useEffect(() => {
    const refreshToken = window.localStorage.getItem('refresh');

    if (!refreshToken) return;

    if (online) {
      // Always refresh tokens when the user refreshes the page to get the prismic token
      if (appState.isLoggedIn !== undefined && !appState.isLoggedIn) {
        refreshTokens(refreshToken);
      }

      // Clear previous interval before setting a new one
      if (refreshIntervalRef.current) {
        clearInterval(refreshIntervalRef.current);
      }

      refreshIntervalRef.current = setInterval(() => refreshTokens(refreshToken), refreshInterval);
    } else if (!online && appState.isLoggedIn === false) {
      //Another branch for PWA?
      setAppState((state) => {
        return {
          ...state,
          isLoggedIn: true,
        };
      });
    }

    return () => {
      if (refreshIntervalRef.current) {
        clearInterval(refreshIntervalRef.current);
      }
    };
  }, [setAppState, appState.isLoggedIn, online, refreshTokens]);

  return null;
};

export default TokenRefresher;
