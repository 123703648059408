import { useMemo } from 'react';
import * as prismic from '@prismicio/client';
const apiEndpoint = 'https://tapio-maastotaulukot.cdn.prismic.io/api/v2';

export const useClient = (appState) =>
  useMemo(() => {
    const { prismicToken } = appState;
    if (prismicToken && prismicToken !== '') {
      return prismic.createClient(apiEndpoint, { accessToken: prismicToken });
    }
  }, [appState]);
