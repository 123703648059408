import React, { useState, useContext, useEffect } from 'react';
import { Paper } from '@mui/material';
import { ReactComponent as CloseIcon } from '../assets/icons/icon-close.svg';
import { UserContext } from '../components/contexts/UserContext';
import { useTranslation } from 'react-i18next';
import { ReactComponent as TimeIcon } from '../assets/icons/icon-time-orange.svg';
import { ReactComponent as KeyIcon } from '../assets/icons/icon-key.svg';
import { ReactComponent as CheckmarkIcon } from '../assets/icons/icon-checkmark.svg';
import apiEndpoint from '../utils/apiEndpoint';
import axios from 'axios';
import { LicensesContext } from '../components/contexts/LicensesContext';
import { AppStateContext } from '../components/contexts/AppStateContext';
import BackButton from '../components/navigation/BackButton';
import NoConnection from '../components/utils/NoConnection';
import { useLocation, useNavigate } from 'react-router-dom';

const Activate = (props) => {
  const { online } = props;
  const { t } = useTranslation('maastotaulukot');
  const [licenseKey, setLicenseKey] = useState('');
  const [user] = useContext(UserContext);
  const [hasOldLicenses, setHasOldLicenses] = useState(false);
  const [success, setSuccess] = useState(false);
  const [licenses] = useContext(LicensesContext);
  const [appState, setAppState] = useContext(AppStateContext);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    document.title = `${t('activate.title')} - TAPIO Maastotaulukot`;
  }, [t]);

  useEffect(() => {
    if (licenses?.length > 0) {
      setHasOldLicenses(true);
    } else {
      setHasOldLicenses(false);
    }
  }, [user, licenses]);

  const activateKey = async () => {
    setAppState({ ...appState, isLoading: true });

    await axios
      .post(`${apiEndpoint}/api/licenses/activate/`, { key: licenseKey })
      .then((response) => {
        console.log(response.data);
        if (response.status === 200) {
          setSuccess(true);
          // Remove license in case it was not valid, update to appState will trigger retrieving valid licenses
          // from the backend
          window.localStorage.removeItem('license');
          window.localStorage.removeItem('unlockedContent');
          setAppState({ ...appState, isLicenseActivated: true, isLoading: false });
        }
      })
      .catch((err) => {
        console.log(err.response);
        setAppState({ ...appState, errorMessage: 'key_invalid', isLoading: false });
      });
  };

  return (
    <div className="login">
      <div className="login-wrap">
        <Paper className="login-box">
          {pathname === '/activate' && <BackButton noWrap />}
          {online !== false ? (
            <>
              {!success ? (
                <>
                  <div className="title-wrap">
                    {hasOldLicenses && !appState.isValidLicense && (
                      <>
                        <TimeIcon />
                        <h1>{t('activate.license_not_valid_title')}</h1>
                        <p>{t('activate.license_not_valid_content')}</p>{' '}
                      </>
                    )}
                    {!hasOldLicenses && !appState.isValidLicense && (
                      <>
                        <KeyIcon />
                        <h1>{t('activate.license_activate_title')}</h1>
                      </>
                    )}
                    {appState.isValidLicense && (
                      <>
                        <KeyIcon />
                        <h1>{t('activate.license_activate_title_2')}</h1>
                      </>
                    )}
                  </div>
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      activateKey();
                    }}
                  >
                    <div className="form-group">
                      <input
                        required
                        className="form-input"
                        type="text"
                        placeholder={t('activate.placeholder')}
                        value={licenseKey}
                        onChange={(e) => setLicenseKey(e.target.value)}
                      />
                      {licenseKey.length > 0 && (
                        <span className="clear" onClick={() => setLicenseKey('')}>
                          <CloseIcon />
                        </span>
                      )}
                    </div>
                    <button className="button default full-width" type="submit">
                      {t('app.activate')}
                    </button>
                    <div className="register-text">
                      <p>{t('activate.no_code')}</p>
                      <a
                        href="https://www.metsakauppa.fi/tuote/maastotaulukot-mobiili/"
                        target="_blank"
                        rel="noreferrer"
                        className="button link"
                      >
                        {t('activate.to_webstore')}
                      </a>
                    </div>
                  </form>
                </>
              ) : (
                <>
                  <div className="title-wrap">
                    <CheckmarkIcon />
                    <h1>{t('activate.success_title')}</h1>
                    <p>{t('activate.success_message')}</p>
                  </div>
                  <button className="button default full-width" onClick={() => navigate('/')}>
                    {t('activate.success_button')}
                  </button>
                </>
              )}
            </>
          ) : (
            <NoConnection />
          )}
        </Paper>
      </div>
    </div>
  );
};

export default Activate;
