import React from 'react';
import { ReactComponent as MinusIcon } from '../../assets/icons/icon-minus.svg';
import { ReactComponent as PlusIcon } from '../../assets/icons/icon-plus.svg';

const CounterInput = (props) => {
  const {
    id,
    label,
    value,
    type,
    onChange,
    min,
    max,
    placeholder,
    plus,
    minus,
    disabled,
    result,
    toFixed,
    highlight,
    highlightColor,
  } = props;

  return (
    <div
      className="form-group rounded"
      style={{ backgroundColor: highlight ? highlightColor : undefined }}
    >
      <label className="form-label" htmlFor={id}>
        {label}
      </label>
      <div className="form-input-wrap">
        {result ? (
          <p>{typeof value === 'number' ? value.toFixed(toFixed) : value}</p>
        ) : (
          <input
            className="form-input counter-input"
            id={id}
            type={type}
            min={min}
            max={max}
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            disabled={disabled}
          />
        )}
        {minus && (
          <button onClick={minus} disabled={value <= 0 || value <= min}>
            <MinusIcon />
          </button>
        )}
        {plus && (
          <button onClick={plus} disabled={value >= max}>
            <PlusIcon />
          </button>
        )}
      </div>
    </div>
  );
};

export default CounterInput;
