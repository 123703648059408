import { useEffect } from 'react';
import BackButton from '../components/navigation/BackButton';
import { Link } from 'react-router-dom';
import { List, ListItem, ListItemText, ListItemSecondaryAction, Divider } from '@mui/material';
import { ReactComponent as ChevronRight } from '../assets/icons/icon-chevron-right.svg';
import useSummaArvotData from '../components/utils/useSummaArvotData';
import { useParams } from 'react-router-dom';

const SummaArvotList = () => {
  const { area } = useParams();
  const data = useSummaArvotData();

  useEffect(() => {
    document.title = 'Summa-arvot - TAPIO Maastotaulukot';
  }, []);

  if (!data) {
    return null;
  }

  const listItems = area
    ? Object.keys(data[area]['odotusarvot'])
    : Object.keys(data).filter((item) => item !== 'specialConditions');

  return (
    <div className="page">
      <BackButton parentTitle={area ? 'Summa-arvot laskenta-alueittain' : 'Summa-arvot'} />

      <div className="page-title">
        <h1>{area ?? 'Summa-arvot laskenta-alueittain'}</h1>
      </div>

      <div className={'slices-container'}>
        <List className="nav-slice slice">
          {listItems.map((key) => {
            return (
              <Link
                className={'not-disabled'}
                key={key}
                to={
                  area
                    ? `/summa-arvot/areas/${area}/${encodeURIComponent(key)}`
                    : `/summa-arvot/areas/${encodeURIComponent(key)}`
                }
              >
                <ListItem>
                  <ListItemText primary={<h4>{key}</h4>} />
                  <ListItemSecondaryAction>
                    <ChevronRight />
                  </ListItemSecondaryAction>
                </ListItem>
                <Divider />
              </Link>
            );
          })}
        </List>
      </div>
    </div>
  );
};

export default SummaArvotList;
