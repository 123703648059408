import React, { useState, createContext } from 'react';

export const AppStateContext = createContext();

export const AppStateProvider = (props) => {
  const [appState, setAppState] = useState({
    isLoggedIn: false,
    isLoading: false,
    isValidLicense: false,
    unlockedContent: {
      summa_arvotaulukot: false,
      maastotaulukot: false,
    },
    expires: null,
    errorMessage: '',
    successMessage: '',
    prismicToken: '',
  });
  return (
    <AppStateContext.Provider value={[appState, setAppState]}>
      {props.children}
    </AppStateContext.Provider>
  );
};
