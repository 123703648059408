/* eslint-disable no-useless-computed-key */
import { useContext, useEffect, useState } from 'react';
import { AppStateContext } from '../contexts/AppStateContext';

const useSummaArvotData = () => {
  const [appState] = useContext(AppStateContext);
  const [data, setData] = useState(null);

  useEffect(() => {
    const getData = async () => {
      // paths have to be explicitly manual here because webpack does some static analysis before compilation so variables won't work here
      const data1 = await import('../../data/summa-arvot/Häme-Uusimaa-Kymi.json');
      const data2 = await import('../../data/summa-arvot/Lounais-Suomi.json');
      const data3 = await import('../../data/summa-arvot/Pirkanmaa.json');
      const data4 = await import('../../data/summa-arvot/Keski-Suomi.json');
      const data5 = await import('../../data/summa-arvot/Eteläinen Savo-Karjala.json');
      const data6 = await import('../../data/summa-arvot/Pohjoinen Savo-Karjala.json');
      const data7 = await import('../../data/summa-arvot/Pohjanmaa.json');
      const data8 = await import('../../data/summa-arvot/Pohjois-Pohjanmaan rannikko.json');
      const data9 = await import('../../data/summa-arvot/Kainuu.json');
      const data10 = await import('../../data/summa-arvot/Koillismaa.json');
      const data11 = await import('../../data/summa-arvot/Etelä-Lappi.json');
      const data12 = await import('../../data/summa-arvot/Keski-Lappi.json');
      const data13 = await import('../../data/summa-arvot/Ylä-Lappi.json');
      const specialConds = await import('../../data/summa-arvot/specialConditions.json');

      setData({
        ['Häme-Uusimaa-Kymi']: data1.default,
        ['Lounais-Suomi']: data2.default,
        ['Pirkanmaa']: data3.default,
        ['Keski-Suomi']: data4.default,
        ['Eteläinen Savo-Karjala']: data5.default,
        ['Pohjoinen Savo-Karjala']: data6.default,
        ['Pohjanmaa']: data7.default,
        ['Pohjois-Pohjanmaan rannikko']: data8.default,
        ['Kainuu']: data9.default,
        ['Koillismaa']: data10.default,
        ['Etelä-Lappi']: data11.default,
        ['Keski-Lappi']: data12.default,
        ['Ylä-Lappi']: data13.default,
        ['specialConditions']: specialConds.default,
      });
    };

    if (appState.isValidLicense && appState.unlockedContent.summa_arvotaulukot) {
      getData();
    }
  }, [appState.isValidLicense, appState.unlockedContent.summa_arvotaulukot]);

  return data;
};

export default useSummaArvotData;
