/* eslint-disable no-useless-computed-key */
import { useEffect } from 'react';
import SummaArvotCounter from '../components/counters/SummaArvotCounter';
import BackButton from '../components/navigation/BackButton';
import useSummaArvotData from '../components/utils/useSummaArvotData';
import { useParams } from 'react-router-dom';

const SummaArvotPage = () => {
  const { area, section } = useParams();
  const data = useSummaArvotData();

  useEffect(() => {
    document.title = 'Summa-arvot - TAPIO Maastotaulukot';
  }, []);

  if (!data) {
    return null;
  }

  const sectionData = data[area]['odotusarvot'][section];
  const habitat = Object.keys(data[area]['arvotKasvupaikoittain']).find((key) =>
    section.includes(key)
  );
  const habitatValues = data[area]['arvotKasvupaikoittain'][habitat];

  return (
    <div className="page">
      <BackButton parentTitle={area} />

      <div className="page-title">
        <h1>{area + ' - ' + section}</h1>
      </div>

      <div className={'slices-container extra-bottom-padding'}>
        <div className="paragraph-slice slice">
          <p>
            {'Kasvupaikan metsämaan arvo: '}
            <strong>{habitatValues.price + ' €/ha'}</strong>
          </p>
          <p>
            {'Laskentakorko: '}
            <strong>{(habitatValues.interest * 100).toFixed(2) + ' %'}</strong>
          </p>
          {habitatValues.specialCondition && (
            <p className="red">{habitatValues.specialCondition}</p>
          )}
          <p>{data.specialConditions.isPreferredInput}</p>
          <p>{data.specialConditions.isPreferredResult}</p>
        </div>

        <SummaArvotCounter data={sectionData} specialConditions={data.specialConditions} />
      </div>
    </div>
  );
};

export default SummaArvotPage;
