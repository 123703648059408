import React, { useRef } from 'react';
import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  LineElement,
  Tooltip,
  PointElement,
} from 'chart.js';
import { getRelativePosition } from 'chart.js/helpers';
import Annotation from 'chartjs-plugin-annotation';
import { Line } from 'react-chartjs-2';
import _ from 'lodash';
import useMaastotaulukotData from '../utils/useMaastotaulukotData';

ChartJS.register(LinearScale, CategoryScale, PointElement, LineElement, Tooltip, Annotation);

const Chart = (props) => {
  const { species, area, fertility, m, m2Ha, setM, setM2Ha, stampLimit } = props;

  const chartRef = useRef();

  const jsonData = useMaastotaulukotData();
  const chartData = jsonData?.harvennusmallit_chart_data;

  if (!chartData) return null;

  const xValues = [
    [...chartData[`${species} ${area} ${fertility}`]['stamp_upper'].map((item) => item.x)],
    [...chartData[`${species} ${area} ${fertility}`]['cutting_after_upper'].map((item) => item.x)],
    [...chartData[`${species} ${area} ${fertility}`]['cutting_after_target'].map((item) => item.x)],
    [...chartData[`${species} ${area} ${fertility}`]['cutting_after_lower'].map((item) => item.x)],
    [...chartData[`${species} ${area} ${fertility}`]['ceiling'].map((item) => item.x)],
  ];
  const yValues = [
    [...chartData[`${species} ${area} ${fertility}`]['stamp_upper'].map((item) => item.y)],
    [...chartData[`${species} ${area} ${fertility}`]['cutting_after_upper'].map((item) => item.y)],
    [...chartData[`${species} ${area} ${fertility}`]['cutting_after_target'].map((item) => item.y)],
    [...chartData[`${species} ${area} ${fertility}`]['cutting_after_lower'].map((item) => item.y)],
    [...chartData[`${species} ${area} ${fertility}`]['ceiling'].map((item) => item.y)],
  ];
  const maxX = Math.max(..._.zip.apply(null, xValues).map(_.max));
  const minX = Math.min(..._.zip.apply(null, xValues).map(_.min));
  const maxY = Math.max(...yValues.flat().filter((y) => typeof y === 'number'));

  console.log(maxY);

  const annotation = {
    type: 'point',
    xValue: m,
    yValue: m2Ha,
    backgroundColor: '#61bf1a',
    borderWidth: 0,
  };

  const options = {
    responsive: true,
    animation: {
      duration: 500,
    },
    maintainAspectRatio: false,
    events: ['click'],
    onClick: (e) => {
      const chart = chartRef.current;
      const canvasPosition = getRelativePosition(e, chart);
      const dataX = chart.scales.x.getValueForPixel(canvasPosition.x);
      const dataY = chart.scales.y.getValueForPixel(canvasPosition.y);
      setM(Math.round(dataX));
      setM2Ha(Math.round(dataY));
    },
    plugins: {
      annotation: {
        annotations: [annotation],
      },
      tooltip: {
        enabled: false,
        backgroundColor: 'white',
        titleColor: 'red',
        titleFont: {
          size: 14,
        },
        bodyFont: {
          size: 14,
        },
        bodyColor: 'blue',
        titleMarginBottom: 8,
        bodySpacing: 8,
        caretPadding: 16,
        padding: {
          top: 8,
          right: 8,
          bottom: 8,
          left: 8,
        },
        cornerRadius: 9,
        caretSize: 8,
        borderColor: '#b3b1b2',
        borderWidth: 1,
        boxWidth: 0,
        boxHeight: 0,
      },
    },
    scales: {
      y: {
        min: 6,
        max: Math.round(maxY + 5) % 2 === 0 ? Math.round(maxY + 5) : Math.round(maxY + 5) + 1,
        position: 'left',
        grid: {
          color: '#e9e8e7',
        },
        ticks: {
          color: '#000000',
          autoSkip: false,
          stepSize: 2,
          padding: 0,
        },
        title: {
          display: true,
          color: '#000000',
          text: 'Pohjapinta-ala, m²/ha',
          padding: window.innerWidth >= 768 ? 16 : 0,
        },
      },
      x: {
        min: minX,
        grid: {
          color: '#e9e8e7',
        },
        ticks: {
          color: '#000000',
          autoSkip: false,
          padding: 0,
        },
        title: {
          display: true,
          color: '#000000',
          text: 'Valtapituus, metriä',
          padding: window.innerWidth >= 768 ? 16 : 0,
        },
      },
    },
  };

  const labels = Array.from({ length: maxX + 1 }, (v, i) => i);

  const data = {
    labels,
    datasets: [
      {
        label: 'Leimausraja',
        data: chartData[`${species} ${area} ${fertility}`]['stamp_upper'],
        borderColor: 'brown',
        backgroundColor: 'brown',
        pointBorderColor: 'rgba(0,0,0,0)',
        pointBackgroundColor: 'rgba(0,0,0,0)',
        borderDash: [8, 8],
      },
      {
        label: 'Tavoitepuuston vaihteluväli ylä',
        data: chartData[`${species} ${area} ${fertility}`]['cutting_after_upper'],
        borderColor: '#93c01f',
        pointBorderColor: 'rgba(0,0,0,0)',
        pointBackgroundColor: 'rgba(0,0,0,0)',
      },
      {
        label: 'Tavoitepuuston vaihteluväli ala',
        data: chartData[`${species} ${area} ${fertility}`]['cutting_after_lower'],
        borderColor: '#93c01f',
        pointBorderColor: 'rgba(0,0,0,0)',
        pointBackgroundColor: 'rgba(0,0,0,0)',
      },
      {
        label: 'Tavoitepuusto',
        data: chartData[`${species} ${area} ${fertility}`]['cutting_after_target'],
        borderColor: '#09984a',
        pointBorderColor: 'rgba(0,0,0,0)',
        pointBackgroundColor: 'rgba(0,0,0,0)',
      },
      {
        label: 'Lakiraja',
        data: chartData[`${species} ${area} ${fertility}`]['ceiling']?.map((item, i) => {
          if (i === chartData[`${species} ${area} ${fertility}`]['ceiling'].length - 1) return item;

          if (species === 'Rauduskoivu') {
            return {
              x: item.x,
              y: chartData[`${species} ${area} ${fertility}`]['ceiling'][i].y,
            };
          } else {
            // law limit y values have offset of -1
            // for example, x 12.0 uses the y value of x 13.0

            return {
              x: item.x,
              y: chartData[`${species} ${area} ${fertility}`]['ceiling'][i + 1].y,
            };
          }
        }),
        borderColor: '#c1272d',
        pointBorderColor: 'rgba(0,0,0,0)',
        pointBackgroundColor: 'rgba(0,0,0,0)',
        stepped: true,
      },
    ],
  };

  return (
    <div className="chart">
      {!stampLimit && <p className="stamplimit-alert red">Leimausraja ei täyty!</p>}
      <div className="chart-wrap" style={{ height: 500 }}>
        <Line ref={chartRef} data={data} options={options} />
      </div>
      <div className="legend">
        <p className="stamplimit">Leimausraja</p>
        <p className="cutting-after-target">Tavoitepuusto</p>
        <p className="cutting-after-limit">Tavoitepuuston vaihteluväli</p>
        <p className="ceiling">Lakiraja</p>
      </div>
    </div>
  );
};

export default Chart;
