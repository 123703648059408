import React, { useState, useEffect } from 'react';
import CounterInput from './CounterInput';
import CounterSlider from './CounterSlider';
import { Tabs, Tab } from '@mui/material';
import useMaastotaulukotData from '../utils/useMaastotaulukotData';

const VolumeOfSingleTree = (props) => {
  const { slice } = props;

  const data = useMaastotaulukotData();
  const [tab, setTab] = useState(0);

  //calc states
  const [d, setD] = useState(22);
  const [h, setH] = useState(18);
  const [logValue, setLogValue] = useState(0);
  const [fiberValue, setFiberValue] = useState(0);
  const [chestHeight, setChestHeight] = useState(true);
  const [stumpHeight, setStumpHeight] = useState(false);
  const [volume, setVolume] = useState(0);
  const [logVolume, setLogVolume] = useState(0);
  const [fiberVolume, setFiberVolume] = useState(0);
  const [value, setValue] = useState(0);

  const fiberTables = (() => {
    if (!data) {
      return null;
    }

    return {
      0: data.kuitu_jakauma_manty,
      1: data.kuitu_jakauma_kuusi,
      2: data.kuitu_jakauma_koivu,
    };
  })();
  const logTables = (() => {
    if (!data) {
      return null;
    }

    return {
      0: data.tukki_jakauma_manty,
      1: data.tukki_jakauma_kuusi,
      2: data.tukki_jakauma_koivu,
    };
  })();

  useEffect(() => {
    if (!fiberTables || !logTables) {
      return;
    }

    let diameter = d;
    if (stumpHeight) {
      diameter = parseInt((0.75 * d).toFixed(0));
    }
    let vol = 0;
    if (tab === 0) {
      vol =
        0.036089 *
        Math.pow(diameter, 2.01395) *
        Math.pow(0.99676, diameter) *
        Math.pow(h, 2.07025) *
        Math.pow(h - 1.3, -1.07209);
    } else if (tab === 1) {
      vol =
        0.022927 *
        Math.pow(d, 1.91505) *
        Math.pow(0.99146, d) *
        Math.pow(h, 2.82541) *
        Math.pow(h - 1.3, -1.53547);
    } else if (tab === 2) {
      vol =
        0.011197 *
        Math.pow(d, 2.10253) *
        Math.pow(0.986, d) *
        Math.pow(h, 3.98519) *
        Math.pow(h - 1.3, -2.659); // '= 0,011197 * d^2,10253 * (0,98600)^d * h^3,98519 * (h-1,3)^(-2,65900)
    }
    const amountOfLog = logTables[tab][diameter - 7][h - 4];
    const amountOfFiber = fiberTables[tab][diameter - 7][h - 4];
    const logVol = (vol * amountOfLog) / 100.0;
    const fiberVol = (vol * amountOfFiber) / 100.0;
    setVolume(vol);
    setLogVolume(logVol);
    setFiberVolume(fiberVol);
    setValue(logValue * (logVol / 1000) + fiberValue * (fiberVol / 1000));
  }, [
    d,
    h,
    tab,
    setVolume,
    setLogVolume,
    setFiberVolume,
    setValue,
    fiberValue,
    logValue,
    stumpHeight,
    fiberTables,
    logTables,
  ]);

  useEffect(() => {
    const logVal = window.localStorage.getItem(`logValue${tab}`);
    const fiberVal = window.localStorage.getItem(`fiberValue${tab}`);

    if (logVal) {
      setLogValue(parseInt(logVal));
    } else {
      setLogValue(slice?.primary[`log_value_${tab}`] ? slice.primary[`log_value_${tab}`] : 0);
    }

    if (fiberVal) {
      setFiberValue(parseInt(fiberVal));
    } else {
      setFiberValue(slice?.primary[`fiber_value_${tab}`] ? slice.primary[`fiber_value_${tab}`] : 0);
    }

    if (tab === 2) {
      setChestHeight(true);
      setStumpHeight(false);
    }
  }, [tab, slice]);

  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  return (
    <div className="slice counter">
      <Tabs value={tab} onChange={handleChange}>
        <Tab label="Mänty" />
        <Tab label="Kuusi" />
        <Tab label="Koivu" />
      </Tabs>

      <CounterSlider
        defaultValue={0}
        label="Läpimitta (cm)"
        min={7}
        max={50}
        value={d}
        onChange={(e, newValue) => setD(newValue)}
      />

      {tab !== 2 && (
        <div className="form-row">
          <div className="form-checkbox">
            <label htmlFor="chest-height">Rinnankorkeudelta (1,3 m)</label>
            <input
              id="chest-height"
              type="radio"
              checked={chestHeight}
              value={chestHeight}
              onChange={() => {
                setChestHeight(!chestHeight);
                setStumpHeight(!stumpHeight);
              }}
            />
            <span />
          </div>
          <div className="form-checkbox">
            <label htmlFor="stump-height">Kannonkorkeudelta</label>
            <input
              id="stump-height"
              type="radio"
              checked={stumpHeight}
              value={stumpHeight}
              onChange={() => {
                setChestHeight(!chestHeight);
                setStumpHeight(!stumpHeight);
              }}
            />
            <span />
          </div>
        </div>
      )}

      <CounterSlider
        defaultValue={0}
        label="Pituus (m)"
        min={4}
        max={30}
        value={h}
        onChange={(e, newValue) => setH(newValue)}
      />

      <CounterSlider
        defaultValue={0}
        label="Tukkipuun arvo (€/m³)"
        min={0}
        max={100}
        value={logValue}
        onChange={(e, newValue) => {
          window.localStorage.setItem(`logValue${tab}`, String(newValue));
          setLogValue(newValue);
        }}
      />
      <CounterSlider
        defaultValue={0}
        label="Kuitupuun arvo (€/m³)"
        min={0}
        max={100}
        value={fiberValue}
        onChange={(e, newValue) => {
          window.localStorage.setItem(`fiberValue${tab}`, String(newValue));
          setFiberValue(newValue);
        }}
      />

      <div className="form-row result">
        <CounterInput
          type="number"
          label="Puun tilavuus (dm³)"
          value={volume}
          placeholder="0"
          result
        />
        <CounterInput
          type="number"
          label="Puun arvo (€)"
          value={value}
          placeholder="0"
          result
          toFixed={2}
        />
      </div>
      <div className="form-row result">
        <CounterInput
          type="number"
          label="Tukkipuuta (dm³)"
          value={logVolume}
          placeholder="0"
          result
        />
        <CounterInput
          type="number"
          label="Kuitupuuta (dm³)"
          value={fiberVolume}
          placeholder="0"
          result
        />
      </div>

      <p className="small">
        Tulokset perustuvat Laasasenahon (1982) laatimiin puun tilavuusyhtälöihin. Mallien
        keskivirhe on männyllä 7,2 %. Malleissa tukkipuun minimiläpimitta on 18 cm ja kuitupuun 7
        cm. Kantoläpimitta on muunnettu rinnankorkeusläpimitaksi Hakkilan (1976) malleilla (Folia
        Forestalia 292).
      </p>
    </div>
  );
};

export default VolumeOfSingleTree;
