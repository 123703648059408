import React, { useState } from 'react';
import { ReactComponent as CloseIcon } from '../../assets/icons/icon-close.svg';
import { Accordion, AccordionSummary, AccordionDetails, Grid } from '@mui/material';
import { ReactComponent as ChevronDown } from '../../assets/icons/icon-chevron-down.svg';
import useMaastotaulukotData from '../utils/useMaastotaulukotData';

const HeatAmountsByMunicipality = () => {
  const data = useMaastotaulukotData();
  const [search, setSearch] = useState('');

  if (!data) {
    return null;
  }

  let results = [];

  if (search.length >= 2) {
    results = data.lamposummat.filter((item) => {
      return item.kunta?.toLowerCase().includes(search.toLowerCase());
    });
  }

  return (
    <div className="slice counter">
      <div className="input-wrap">
        <div className="form-group rounded">
          <input
            className="form-input"
            type="text"
            placeholder="Hae kunta"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          {search.length > 0 && (
            <span
              className="clear"
              onClick={() => {
                setSearch('');
              }}
            >
              <CloseIcon />
            </span>
          )}
        </div>
      </div>
      {results.map((item, i) => {
        return (
          <Accordion key={i} className="accordion-slice slice">
            <AccordionSummary expandIcon={<ChevronDown />}>
              <div>
                <h3>{item.kunta}</h3>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <div>
                <Grid container spacing={4}>
                  <Grid item>
                    <p className="small no-margin">Metsälakialue</p>
                    <p className="no-margin">
                      <b>{item.metsalakialue}</b>
                    </p>
                  </Grid>
                  <Grid item>
                    <p className="small no-margin">Suositusalue</p>
                    <p className="no-margin">
                      <b>{item.suositusalue}</b>
                    </p>
                  </Grid>
                </Grid>
                <Grid container spacing={4}>
                  <Grid item>
                    <p className="small no-margin">{item.luku_1}</p>
                    <p className="no-margin">
                      <b>{item.luku_1_arvo}</b> <span className="small">{item.luku_1_yksikko}</span>
                    </p>
                  </Grid>
                  <Grid item>
                    <p className="small no-margin">{item.luku_2}</p>
                    <p className="no-margin">
                      <b>{item.luku_2_arvo}</b> <span className="small">{item.luku_2_yksikko}</span>
                    </p>
                  </Grid>
                  <Grid item>
                    <p className="small no-margin">{item.luku_3}</p>
                    <p className="no-margin">
                      <b>{item.luku_3_arvo}</b> <span className="small">{item.luku_3_yksikko}</span>
                    </p>
                  </Grid>
                </Grid>
              </div>
            </AccordionDetails>
          </Accordion>
        );
      })}
    </div>
  );
};

export default HeatAmountsByMunicipality;
