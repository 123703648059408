import React, { useEffect } from 'react';
import BackButton from '../components/navigation/BackButton';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

const PrivacyPolicy = () => {
  const { pathname } = useLocation();
  const { t } = useTranslation('maastotaulukot');

  useEffect(() => {
    document.title = `${t('privacy_policy.title')} - TAPIO Maastotaulukot`;
  }, [t, pathname]);

  return (
    <div className="page">
      <BackButton />
      <div className="page-title">
        <h2>{t('privacy_policy.title')}</h2>
      </div>
      <div className="slices-container">
        <h3>Tietosuojaseloste TAPIO Maastotaulukot -asiakkaille</h3>
        <p>
          Tapio-konserniin kuuluvat Tapio Oy ja Tapio Palvelut Oy.
          <br />
          <br />
          Tämä tietosuojaseloste koskee Tapio Palvelut Oy:n asiakkaita (jäljempänä Tapio). Tämä
          Tietosuojaseloste (jäljempänä ”Seloste”) kertoo, kuinka Tapio Palvelut Oy (jäljempänä
          Tapio) kerää, käsittelee ja luovuttaa henkilötietoja tarjottujen tuotteiden ja palveluiden
          (”Palvelu”) sekä verkkosivun tapio.fi yhteydessä. Lue tämä Seloste huolellisesti ennen
          Palvelun käyttämistä tai verkkosivun selaamista.
          <br />
          <br />
          <b>1. Rekisterinpitäjä</b>
          <br />
          <br />
          Sovellettavan tietosuojalain mukainen rekisterinpitäjä on Tapio Palvelut Oy yhdessä
          (jäljempänä "Tapio", ”me” ”meitä” tai ”meidän”). Tapio vastaa siitä, että henkilötietojasi
          käsitellään tätä Selostetta ja soveltuvia tietosuojalakeja noudattaen.
          <br />
          <br />
          Rekisterinpitäjän yhteystiedot:
          <br />
          <b>Tapio Palvelut Oy</b> (Y-tunnus: 0498749-8)
          <br />
          Maistraatinportti 4A, 00240 Helsinki
          <br />
          Puh: 0294 32 6000
          <br />
          <br />
          <br />
          <b>2. Henkilötietojen kerääminen</b>
          <br />
          <br />
          Henkilötietojasi voidaan kerätä eri tavoin. Pääsääntöisesti keräämme ja käsittelemme
          henkilötietoja, jotka:
        </p>
        <ul>
          <li>
            olet antanut meille, kun olet yhteydessä meihin tai asioit kanssamme, esim. kun ostat
            Palveluamme tai rekisteröidyt Palveluumme, tilaat uutiskirjeemme tai otat meihin
            yhteyttä pyytääksesi tarjousta tai tietoja
          </li>
          <li>
            syntyvät Palvelua käytettäessä tai verkkosivulla vierailun yhteydessä, esim. kun
            kirjaudut Palveluun
          </li>
          <li>
            saadaan muista lähteistä, soveltuvien lakien sallimassa laajuudessa esim.
            Internet-sivut, Kaupparekisteri, Väestötietojärjestelmä, Yritys- ja
            yhteisötietojärjestelmä tai Postin osoitetietojärjestelmä.
          </li>
        </ul>
        <p>
          Sinun ei ole pakko antaa meille henkilötietojasi, mutta mikäli päätät näin, emme
          mahdollisesti pysty tarjoamaan palveluamme sinulle.
          <br />
          <br />
          Keräämme ja käsittelemme esimerkiksi seuraavia henkilötietoryhmiä:
        </p>
        <ul>
          <li>
            perustiedot, kuten nimi, ammattinimike ja suhteesi edustamaasi yritykseen ja
            yhteystiedot (sähköposti, osoite ja puhelinnumero) sekä asiointikieli
          </li>
          <li>
            asiakassuhteeseen liittyvät tiedot, kuten Palvelua ja tilausta koskevat tiedot,
            maksutiedot, laskutustiedot, markkinointiluvat ja -kiellot
          </li>
          <li>
            asiakasyhteydenotot ja niihin liittyvä kirjeenvaihto sekä rekisteröityjen oikeuksia
            koskevat kirjaukset
          </li>
          <li>
            Palvelumme käytön yhteydessä syntyneet henkilötiedot tai verkkosivumme käytön yhteydessä
            kerätyt tiedot esim. käyttäjätunnukset, salasanat, tunnistamiseen liittyvät tiedot
          </li>
          <li>
            Palvelun käyttöä koskevat lokitiedot, evästeiden tai samankaltaisten teknologioiden
            avulla verkkosivulta kerätyt tiedot (laitetunnus ja -tyyppi, käyttöjärjestelmä ja
            sovellusasetukset)
          </li>
          <li>muut tapauskohtaisesti määritellyt tiedot antamasi suostumuksen perusteella.</li>
        </ul>
        <p>
          <br />
          <b>3. Henkilötietojen käsittelyn tarkoitus ja oikeusperuste</b>
          <br />
          <br />
          Keräämme ja käsittelemme vain henkilötietoja, jotka ovat tarpeen liiketoimintamme
          harjoittamiseen, asiakassuhteen hoitamiseen ja asianmukaisia kaupallisia tarkoituksia
          varten.
          <br />
          <br />
          Käsittelemme henkilötietojasi seuraaviin tarkoituksiin:
          <br />
          <br />
          <b>a) Palvelun tarjoaminen ja asiakassuhteiden hallinta</b>
          <br />
          Käsittelemme henkilötietoja ensisijaisesti tarjotaksemme ja toimittaaksemme sinulle tai
          edustamallesi yritykselle Palvelua. Voidaksemme tehdä niin ylläpidämme ja hoidamme
          asiakassuhdetta sinun tai edustamasi yrityksen ja meidän välillä. Tässä tapauksessa
          henkilötietojen käsittely perustuu sinun tai edustamasi yrityksen ja meidän väliseen
          sopimukseen.
          <br />
          <br />
          <b>b) Markkinointi</b>
          <br />
          Voimme olla sinuun yhteydessä kertoaksemme Palvelun uusista ominaisuuksista tai
          markkinoidaksemme ja myydäksemme sinulle muita palveluita. Voimme käsitellä sinun
          henkilötietojasi myös markkinointitutkimuksia ja asiakaskyselyitä varten. Henkilötietojen
          käsittely perustuu meidän oikeutettuun etuumme tarjota tietoa osana Palvelua ja
          markkinoidaksemme muita palveluitamme sinulle. Sinulla on milloin tahansa oikeus vastustaa
          henkilötietojesi käsittelyä suoramarkkinointiin (katso tämän Selosteen jakso 8).
          <br />
          <br />
          <b>c) Palvelun kehittäminen, tietoturva ja sisäinen raportointi</b>
          <br />
          Käsittelemme henkilötietoja myös huolehtiaksemme Palvelun ja verkkosivun tietoturvasta,
          Palvelun ja verkkosivun laadun parantamiseksi sekä Palvelun kehittämiseksi. Voimme myös
          koostaa henkilötietojen perusteella sisäisiä raportteja johdon käyttöön liiketoimintamme
          asianmukaista johtamista varten. Näissä tapauksissa henkilötietojen käsittely perustuu
          meidän oikeutettuun etuun varmistaa Palvelumme ja verkkosivumme asianmukainen tietoturva
          sekä saada riittävät ja asianmukaiset tiedot Palvelun kehittämiseen ja liiketoimintamme
          johtamiseen.
          <br />
          <br />
          <b>d) Lakien noudattaminen</b>
          <br />
          Voimme käsitellä henkilötietojasi lakisääteisten velvoitteidemme täyttämiseksi esim.
          koskien kirjanpitoa tai toteuttaaksemme viranomaisten (esim. veroviranomainen) lakiin
          perustuvat tietopyynnöt.
          <br />
          <br />
          <b>e) Muut tarkoitukset, joihin olet suostunut</b>
          <br />
          Käsittelemme henkilötietojasi myös muihin tarkoituksiin, mikäli olet antanut suostumuksesi
          tällaiseen käsittelyyn.
          <br />
          <br />
          <br />
          <b>4. Henkilötietojen siirrot ja luovutukset</b>
          <br />
          <br />
          Voimme luovuttaa henkilötietoja muille Tapio-konserniin kuuluville yrityksille soveltuvan
          lain sallimissa rajoissa tässä Selosteessa kuvattuja tarkoituksia varten, mukaan lukien
          edellä mainittujen yritysten tuotteiden ja palveluiden markkinointiin. Henkilötietoja
          voidaan siirtää Tapio-konsernin yhtiöiden välillä myös sisäisiä hallinnollisia
          tarkoituksia varten, kuten raportointitarkoituksessa ja harjoittaaksemme liiketoimintaamme
          tehokkaasti, kuten käyttääksemme keskitettyjä tieto- ja viestintäjärjestelmiä.
          Henkilötietojen luovuttaminen Tapio -konsernin sisällä perustuu meidän oikeutettuun
          etuumme harjoittaa liiketoimintaamme ja hallita asiakassuhteitamme tehokkaasti sekä kertoa
          asiakkaille muiden Tapio-konserniin kuuluvien yhtiöiden palveluista.
          <br />
          <br />
          Voimme luovuttaa henkilötietoja kolmansille osapuolille:
        </p>
        <ul>
          <li>
            lain sallimassa tai vaatimassa laajuudessa, esim. toimivaltaisen viranomaisen esittämän
            tietopyynnön toteuttamiseksi tai oikeudenkäyntimenettelyihin liittyen
          </li>
          <li>
            kun yhteistyökumppanimme käsittelevät toimeksiannostamme henkilötietoja meidän
            puolestamme ja ohjeidemme mukaisesti. Huolehdimme aina henkilötietojesi asianmukaisesta
            käsittelystä mikäli olemme mukana sulautumisessa, yritysjärjestelyssä taikka
            liiketoiminnan tai sen osan myynnissä
          </li>
          <li>
            kun arvioimme, että luovuttaminen on välttämätöntä oikeuksiemme toteuttamiseksi, sinun
            tai muiden turvallisuuden suojelemiseksi, väärinkäytösten tutkimiseksi tai viranomaisen
            pyyntöön vastaamiseksi; ja sinun suostumuksellasi osapuolille, joita suostumus koskee.
          </li>
        </ul>
        <p>
          <br />
          <b>5. Henkilötietojen siirrot EU:n tai ETA-alueen ulkopuolelle</b>
          <br />
          <br />
          Emme tällä hetkellä siirrä asiakkaiden henkilötietoja EU:n ja ETA-alueen ulkopuolelle.
          Mikäli yhteistyökumppanimme muuttuvat voimme siirtää henkilötietoja EU:n tai Euroopan
          talousalueen ulkopuolelle silloin, kun toimeksiantoa hoitava yhteistyökumppanimme on
          sijoittautunut näiden alueiden ulkopuolelle. Näissä tapauksissa huolehdimme
          asianmukaisista suojatoimista rekisteröityjen oikeuksien ja vapauksien varmistamiseksi
          sovellettavan tietosuojalainsäädännön, kuten EU:n yleisen tietosuoja-asetuksen (679/2016),
          mukaisesti.
          <br />
          <br />
          Esimerkiksi, meille markkinointia toteuttava palveluntarjoaja voi siirtää henkilötietoja
          Yhdysvaltoihin palvelun tuottamisen yhteydessä. Tässä tapauksessa henkilötietojen
          asianmukaiset suojatoimet on toteutettu siten, että palveluntarjoaja on sitoutunut
          Yhdysvaltojen ja EU:n väliseen ns. Privacy Shield -järjestelyyn tai käytämme EU:n
          komission hyväksymiä tietosuojan mallisopimuslausekkeita. Lue Privacy Shield järjestelystä
          täältä{' '}
          <a href="https://www.privacyshield.gov/welcome." target="_blank" rel="noreferrer">
            https://www.privacyshield.gov/welcome
          </a>
          .
          <br />
          <br />
          <br />
          <b>6. Evästeet</b>
          <br />
          <br />
          Käytämme myös evästeitä ja muita samankaltaisia teknologioita verkkosivullamme
          maastotaulukot.fi. Evästeet ovat pieniä tekstitiedostoja, joita on sijoitettu laitteeseesi
          hyödyllisen tiedon keräämistä ja muistamista varten, verkkosivumme toiminallisuuden ja
          käytettävyyden parantamiseksi. Voimme käyttää evästeitä ja muita samankaltaisia
          teknologioita myös tilastollisiin tarkoituksiin kuten koostaaksemme tilastoja verkkosivun
          käytöstä ymmärtääksemme kuinka käyttäjät käyttävät verkkosivua ja parantaaksemme
          käyttäjäkokemusta.
          <br />
          <br />
          Voit estää evästeiden asettamisen, rajoittaa evästeiden käyttöä tai poistaa evästeet
          selaimeltasi. Koska evästeet mahdollistavat verkkosivumme toiminnan, evästeiden käytön
          rajoittaminen saattaa vaikuttaa verkkosivun käytettävyyteen.
          <br />
          <br />
          Voit lukea lisää evästeistä täältä{' '}
          <a
            href="https://tapio.fi/evasteilmoitus-tapio-konserni/"
            target="_blank"
            rel="noreferrer"
          >
            https://tapio.fi/evasteilmoitus-tapio-konserni/
          </a>
          .
          <br />
          <br />
          <br />
          <b>7. Henkilötietojen säilyttäminen</b>
          <br />
          <br />
          Henkilötietoja säilytetään ainoastaan niin kauan kuin se on tarpeellista tässä Selosteessa
          määriteltyjen käyttötarkoitusten toteuttamiseksi.
          <br />
          <br />
          Henkilötietoja säilytetään asiakassuhteen ajan. Henkilötietoja voidaan säilyttää
          tarpeellisilta osin myös asiakassuhteen päättymisen jälkeen soveltuvan lain sallimassa tai
          vaatimassa laajuudessa. Esimerkiksi, asiakassuhteen päättymisen jälkeen säilytämme
          tyypillisesti henkilötietoja, jotka ovat tarpeen vaatimuksiin tai kanteisiin vastaamiseksi
          voimassaolevien vanhentumisaikaa koskevien säännösten mukaisesti. Voimme myös esimerkiksi
          säilyttää henkilötietoja tarpeellisilta osin noudattaaksemme antamaasi
          suoramarkkinointikieltoa.
          <br />
          <br />
          Henkilötiedot poistetaan, kun niiden säilyttäminen ei enää ole tarpeen lain tai kummankaan
          osapuolen oikeuksien tai velvollisuuksien toteuttamiseksi.
          <br />
          <br />
          <br />
          <b>8. Sinun oikeutesi</b>
          <br />
          <br />
          Sinulla on oikeus tarkastaa henkilötietosi. Voit milloin tahansa myös pyytää
          henkilötietojesi oikaisemista, päivittämistä tai poistamista. Huomaa kuitenkin, että
          sellaisia henkilötietoja, jotka ovat välttämättömiä tässä Selosteessa määriteltyjen
          käyttötarkoitusten toteuttamiseksi, tai joiden säilyttämistä laki edellyttää, ei voida
          poistaa.
          <br />
          <br />
          Sinulla on oikeus vastustaa tai rajoittaa henkilötietojesi käsittelyä sovellettavan lain
          edellyttämässä laajuudessa.
          <br />
          <br />
          Sinulla on sovellettavan lain mukaisesti tietyissä tapauksissa oikeus siirtää meille
          toimittamasi henkilötiedot järjestelmästä toiseen eli oikeus saada henkilötietosi
          jäsennellyssä, yleisesti käytetyssä, koneellisesti luettavassa muodossa ja siirtää
          henkilötietosi toiselle rekisterinpitäjälle. Käsitellessämme henkilötietojasi suostumuksen
          perusteella sinulla on oikeus milloin tahansa peruuttaa antamasi suostumus. Emme sen
          jälkeen käsittele henkilötietoja, ellei käsittelyyn ole olemassa muuta oikeusperustetta.
          <br />
          <br />
          Voit käyttää oikeuksiasi lähettämällä pyynnön meille osoitteeseen 
          <a href="mailto:tapio@tapio.fi" target="_blank" rel="noreferrer">
            tapio@tapio.fi
          </a>
          .
          <br />
          <br />
          Mikäli koet, että henkilötietojesi käsittely ei ole asianmukaista, sinulla on oikeus
          kääntyä asiassa tietosuojavaltuutetun puoleen. Löydät tietosuojavaltuutetun yhteystiedot
          tietosuojavaltuutetun verkkosivuilta www.tietosuoja.fi
          <br />
          <br />
          <br />
          <b>9. Tietoturva</b>
          <br />
          <br />
          Toteutamme tarkoituksenmukaiset toimenpiteet (mukaan lukien fyysiset, digitaaliset ja
          hallinnolliset toimet) henkilötietojen suojaamiseksi häviämiseltä, tuhoutumiselta,
          väärinkäytöksiltä ja luvattomalta pääsyltä tai luovuttamiselta. Esimerkkinä mainittakoon,
          että henkilötietoihin on pääsy vain henkilöillä, jotka tarvitsevat niitä työtehtäviensä
          hoitamiseksi.
          <br />
          <br />
          Huomaa, että tarkoituksenmukaisetkaan toimienpiteet eivät voi estää kaikkia mahdollisia
          tietoturvaloukkauksia. Henkilötietojen tietoturvaloukkaustilanteissa ilmoitamme sinulle
          asiasta soveltuvien lakien mukaisesti.
          <br />
          <br />
          <br />
          <b>10. Selosteen muuttaminen</b>
          <br />
          <br />
          Meillä on oikeus muuttaa tätä Selostetta. Ilmoitamme muutoksista sinulle verkkosivullamme,
          jossa on aina viimeisin versio tästä Selosteesta.
          <br />
          <br />
          <br />
          <b>11. Ota yhteyttä</b>
          <br />
          <br />
          Voit kysyä tästä Selosteesta tai tarkempia tietoja henkilötietojesi käsittelystä ottamalla
          meihin yhteyttä sähköpostitse osoitteeseen{' '}
          <a href="mailto:tapio@tapio.fi" target="_blank" rel="noreferrer">
            tapio@tapio.fi
          </a>
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
