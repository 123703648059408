import React, { useState } from 'react';
import CounterInput from './CounterInput';
import CounterSlider from './CounterSlider';
import { Tabs, Tab } from '@mui/material';
import Chart from './Chart';
import useMaastotaulukotData from '../utils/useMaastotaulukotData';

const ThinningPatterns = (props) => {
  const { slice } = props;

  const [tab, setTab] = useState(0);

  // Syötteet
  const [m, setM] = useState(12); // Valtapituus
  const [m2Ha, setM2Ha] = useState(40); // Pohjapinta-ala ppa

  const jsonData = useMaastotaulukotData();
  const cuttingLimits = jsonData?.harvennusmallit;

  if (!cuttingLimits) return null;

  // Prismic harvennusmalli slicen arvot
  const species = slice?.primary?.species; // Puutyyppi
  const area = slice?.primary?.area; // Alue
  const fertility = slice?.primary?.fertility; // Kasvupaikkaluokka

  // Päättelyt
  const avgLength = calculateAvgLength(m, species, fertility); // Keskipituus
  const stampLimit =
    cuttingLimits[`${species} ${area} ${fertility}`]?.stamp_upper?.find(
      (x) => x.typical_height === m
    )?.['PPA'] ?? 0; // Leimaus ppa m2/ha
  const leftOverLimit =
    cuttingLimits[`${species} ${area} ${fertility}`]?.cutting_after_target?.find(
      (x) => x.typical_height === m
    )?.['PPA'] ?? 0; // Jäävä ppa m2/ha

  //Tulokset näytöllä
  const wastageArea = m2Ha - leftOverLimit; // Harvennusvoimakkuus m2/ha (Sama kuin Poistuman ppa m2/ha)
  const fellingWastageArea = calculateFellingWastageArea(species, avgLength, wastageArea); // Hakkuupoistuma m3/ha
  const isStampLimitCrossed = m2Ha > stampLimit ? true : false; // Täyttyykö leimausraja?

  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  const renderResult = () => (
    <div className="form-row result">
      <CounterInput
        type="number"
        label="Hakkuupoistuma (m³/ha)"
        value={Math.round(fellingWastageArea)}
        placeholder="0"
        result
      />
      <CounterInput
        type="number"
        label="Harv.voimakkuus (m²/ha)"
        value={Math.round(wastageArea)}
        placeholder="0"
        result
      />
    </div>
  );

  return (
    <div className="thinning-patterns-slice counter slice">
      <Tabs value={tab} onChange={handleChange}>
        <Tab label="Laskuri" />
        <Tab label="Kuvaaja" />
      </Tabs>
      {tab === 0 && (
        <>
          <CounterSlider
            defaultValue={0}
            label="Valtapituus (m)"
            min={8}
            max={24}
            value={m}
            onChange={(e, newValue) => {
              setM(newValue);
            }}
          />
          {!isStampLimitCrossed && <p className="red">Leimausraja ei täyty!</p>}
          <CounterSlider
            defaultValue={0}
            label="Pohjapinta-ala (m²/ha)"
            min={5}
            max={40}
            value={m2Ha}
            onChange={(e, newValue) => {
              setM2Ha(newValue);
            }}
          />

          {renderResult()}
        </>
      )}
      {tab === 1 && (
        <>
          <div className="form-row result">
            <CounterInput type="text" label="Valtapituus (m) [x]" value={m} result />
            <CounterInput type="text" label="Pohjapinta-ala (m²/ha) [y]" value={m2Ha} result />
          </div>
          {renderResult()}
          <Chart
            species={slice?.primary?.species}
            area={slice?.primary?.area}
            fertility={slice?.primary?.fertility}
            m={m}
            setM={setM}
            m2Ha={m2Ha}
            setM2Ha={setM2Ha}
            stampLimit={isStampLimitCrossed}
          />
        </>
      )}
    </div>
  );
};

function calculateAvgLength(m, species, fertility) {
  let avgLength = 0; //Keskipituus

  if (species === 'Mänty') {
    const calc1 = 1.5638 + 1.174 * m - 2.1788 * Math.log(m) + 0.482;
    const calc2 = 1.5638 + 1.174 * m - 2.1788 * Math.log(m);

    switch (fertility) {
      case 'Tuore kangas':
        avgLength = calc1;
        break;
      case 'Kuivahko kangas':
        avgLength = calc1;
        break;
      case 'Kuiva kangas':
        avgLength = calc1;
        break;
      case 'Mustikkaturvekangas':
        avgLength = calc2;
        break;
      case 'Puolukkaturvekangas':
        avgLength = calc2;
        break;
      case 'Varputurvekangas':
        avgLength = calc2;
        break;
      default:
        break;
    }
  }

  if (species === 'Kuusi') {
    avgLength = 1.6098 + 1.09 * m - 2.2004 * Math.log(m) + 0.403;
  }

  if (species === 'Hieskoivu' || species === 'Rauduskoivu') {
    avgLength = 1.5195 + 1.1035 * m - 1.8378 * Math.log(m);
  }

  return avgLength;
}

function calculateFellingWastageArea(species, avgLength, wastageArea) {
  let fellingWastageArea = 0; //Hakkuupoistuma m3/ha

  if (species === 'Mänty') {
    fellingWastageArea =
      (0.4116 - 0.04275 * Math.pow(avgLength, 1.5) + 0.6359 * avgLength) * wastageArea;
  }

  if (species === 'Kuusi') {
    fellingWastageArea =
      (1.3187 + 0.00099 * Math.pow(avgLength, 2) + 0.3978 * avgLength) * wastageArea;
  }

  if (species === 'Hieskoivu' || species === 'Rauduskoivu') {
    fellingWastageArea =
      (0.4907 - 0.00137 * Math.pow(avgLength, 2) + 0.4556 * avgLength) * wastageArea;
  }

  return fellingWastageArea;
}

export default ThinningPatterns;
