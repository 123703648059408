import { useContext, useEffect, useState } from 'react';
import { AppStateContext } from '../contexts/AppStateContext';

const useMaastotaulukotData = () => {
  const [appState] = useContext(AppStateContext);
  const [data, setData] = useState(null);

  useEffect(() => {
    const getData = async () => {
      // paths have to be explicitly manual here because webpack does some static analysis before compilation so variables won't work here
      const harvennusmallit_chart_data = await import('../../data/harvennusmallit_chart_data.json');
      const harvennusmallit = await import('../../data/harvennusmallit.json');
      const kuitu_jakauma_koivu = await import('../../data/kuitu_jakauma_koivu.json');
      const kuitu_jakauma_kuusi = await import('../../data/kuitu_jakauma_kuusi.json');
      const kuitu_jakauma_manty = await import('../../data/kuitu_jakauma_manty.json');
      const lamposummat = await import('../../data/lamposummat_uusi.json');
      const relaskooppi_koivikko = await import('../../data/relaskooppi_koivikko.json');
      const relaskooppi_kuusikko = await import('../../data/relaskooppi_kuusikko.json');
      const relaskooppi_mannikko = await import('../../data/relaskooppi_mannikko.json');
      const tukki_jakauma_koivu = await import('../../data/tukki_jakauma_koivu.json');
      const tukki_jakauma_kuusi = await import('../../data/tukki_jakauma_kuusi.json');
      const tukki_jakauma_manty = await import('../../data/tukki_jakauma_manty.json');

      setData({
        harvennusmallit_chart_data: harvennusmallit_chart_data.default,
        harvennusmallit: harvennusmallit.default,
        kuitu_jakauma_koivu: kuitu_jakauma_koivu.default,
        kuitu_jakauma_kuusi: kuitu_jakauma_kuusi.default,
        kuitu_jakauma_manty: kuitu_jakauma_manty.default,
        lamposummat: lamposummat.default,
        relaskooppi_koivikko: relaskooppi_koivikko.default,
        relaskooppi_kuusikko: relaskooppi_kuusikko.default,
        relaskooppi_mannikko: relaskooppi_mannikko.default,
        tukki_jakauma_koivu: tukki_jakauma_koivu.default,
        tukki_jakauma_kuusi: tukki_jakauma_kuusi.default,
        tukki_jakauma_manty: tukki_jakauma_manty.default,
      });
    };

    if (appState.isValidLicense && appState.unlockedContent.maastotaulukot) {
      getData();
    }
  }, [appState.isValidLicense, appState.unlockedContent.maastotaulukot]);

  return data;
};

export default useMaastotaulukotData;
